import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ErrorServiceService {
  errorEvent = new EventEmitter();
  constructor() { }

}


/**
 * Ref https://medium.com/@manivel45/event-emitter-vs-subject-cross-component-communication-8934376605e1
 */
