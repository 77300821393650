import { Component, OnInit } from '@angular/core';
import { ErrorServiceService } from '../error-service.service';


@Component({
  selector: 'app-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.css']
})
export class ServerErrorComponent implements OnInit {

  constructor(private service: ErrorServiceService) {
  }

  errorMessage = "";

  ngOnInit() {
    // subscribe receives the value.
    let _self = this;
    this.service.errorEvent.subscribe((data) => {
      _self.errorMessage = data;
    });
  }

}
